import React from "react";
import { Button } from "react-bootstrap";
import revertIcon from "../../assets/images/fa_refresh.svg";
import { getRevertUser } from "../../api";
import "./style.scss";

export default function RevertButtonComp({ propsMargin }) {
  const handleRevertUser = () => {
    window.open(getRevertUser(), "_self");
  };
  return (
    <div
      className={
        propsMargin ? "revert-props-btn-wrapper" : "revert-btn-wrapper"
      }
    >
      <Button
        variant="danger"
        className="revert_btn"
        onClick={handleRevertUser}
      >
        <img src={revertIcon} alt="revert" className="revert-img me-1" />
        Revert User
      </Button>
    </div>
  );
}
