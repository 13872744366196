import React from "react";
import { Modal } from "react-bootstrap";
import UpArrowIcon from "../../assets/images/dashboard/arrow_outward.svg";
import CheckCircle from "../../assets/images/dashboard/check_circle.svg";
import ManagePop from "../../assets/images/dashboard/manage-pop-icon.svg";
import CloseIcon from "../../assets/images/dashboard/close.svg";

export default function PendingModal(props) {
  const { pendingModalData, showPendingModal, handlePendingModalClose } = props;
  return (
    <div>
      <Modal
        show={showPendingModal}
        onHide={() => handlePendingModalClose()}
        className="modal-email-wrapper payroll-gusto-pto-modal-wrapper"
      >
        <Modal.Header className="modal-manage-header">
          <h3>Manage pending requests!</h3>
          <img
            src={CloseIcon}
            alt="close-icon"
            onClick={() => handlePendingModalClose()}
          />
        </Modal.Header>
        <Modal.Body className="manage-payroll-modal">
          <p>
            To ensure accurate PTO balances, please manage the pending time off
            requests before processing payroll
          </p>
          <div className="manage-pop-content">
            <div className="manage-pop-sub-wrapper">
              <div className="manage-pop-title">
                <img src={ManagePop} alt="manage-pop" />
                <h5>Manage time card request</h5>
              </div>
              {pendingModalData?.timecard &&
              pendingModalData?.timecard !== 0 ? (
                <div className="manage-pop-incomplete">
                  <a href="/multi-card-approval">
                    <h5>Go to time keeping</h5>
                    <img src={UpArrowIcon} alt="check-circle" />
                  </a>
                </div>
              ) : (
                <div className="manage-pop-completed">
                  <h5>Completed</h5>
                  <img src={CheckCircle} alt="check-circle" />
                </div>
              )}
            </div>
            <div className="manage-pop-sub-wrapper">
              <div className="manage-pop-title">
                <img src={ManagePop} alt="manage-pop" />
                <h5>Manage time off request</h5>
              </div>
              {pendingModalData?.pto && pendingModalData?.pto !== 0 ? (
                <div className="manage-pop-incomplete">
                  <a href="/time-off-calendar">
                    <h5>Go to time off</h5>
                    <img src={UpArrowIcon} alt="check-circle" />
                  </a>
                </div>
              ) : (
                <div className="manage-pop-completed">
                  <h5>Completed</h5>
                  <img src={CheckCircle} alt="check-circle" />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
