export const mustSpecialCharacterCheck = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const mustLowerCaseLetters = /^(?=.*[a-z])/;
export const onlyNumbers = /^(?=.*\d)/;
export const mustNumbers = /^[0-9\-]*$/;
export const mustNumberswithSlace = /^[0-9\-]*$/;
export const mustNumberswithPhone = /^[0-9\-\()]*$/;
export const mustUpperCaseLetters = /^(?=.*[A-Z])/;
export const emailRegx =/^(?!.{61})[a-zA-Z0-9+-.!#$%^&*_']+(?:\.[a-zA-Z0-9+-.!#$%^&*_']*)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,}$/;
// export const emailRegx =  /^(?!.{61})[a-zA-Z0-9+]+(?:\.[a-zA-Z0-9+]*)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/; only + symbol allowed
// export const emailRegx = /^(?!.{61})[a-zA-Z0-9]+(?:\.[a-z0-9]+)*@[a-z0-9-]+(?:\.[a-z0-9-]+)*\.[a-zA-Z]{2,6}$/; np special symbols allowed
export const phoneNumberRegx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const requiredField = "This Field is Required"
export const onlyCharacterWithSpace = /^[A-Za-z\s]*$/;
export const characterWithCommaDotHyphenAndApos = /^[.,'\-a-zA-Z\s]+$/;
export const onlyCharacter = "^[A-Za-z]*$"
export const onlyCharacterWithSpaceAndComma = /^[A-Za-z\s\,]*$/;
export const loginEmailRegx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const emailCheckRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
export const fotmatNumber = "/\D[^\.]/g"
export const spaceRegex = /^\S*$/;
export const emptySpace = /\w/;
export const mustAlphaNumeric = /^[a-zA-Z0-9]+$/i;
export const maskNumber = /\d(?=\d{4})/g;
export const salaryValidation = /^(\d+(\.\d*)?|\.\d+)$/;
export const FormatHypen = /-/g;
export const FloattingNo = /[+-]?([0-9]*[.])?[0-9]+/;
export const ssnRegex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
export const onlyCharacterWithSpaceAndDot = /^[A-Za-z.\s]*$/;
export const onlyAlphaNumericWithAmpersandAndHyphenAndSpace = /^[A-Za-z0-9& -]+$/;
export const autocloseTiming = 15000;
export const onlySpaceRegex = /^\s*$/;
