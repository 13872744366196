import moment from "moment";
import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import Buttons from "../../components/Form/Button";
import payrollInfoIcon from "../../assets/images/payroll_info.svg";
import "./style.scss";

export default function PayrollInfo(props) {
  const {
    auto_refresh,
    processPayrollList,
    lastRegularCycleEntry,
    loading,
    handleStageChange,
    completePayrollData,
    lastOffCycleEnty,
    individualInfoText,
  } = props;
  function formatDate(dateString) {
    const formattedDate = moment(dateString, "DD-MM-YYYY HH:mm:ss").format(
      "MM-DD-YYYY"
    );
    return formattedDate;
  }
  return (
    <>
      {!completePayrollData.isfirstpayroll_run ? (
        <div className="exiting-payroll mb-3 px-1">
          <div className="header mb-3">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>Prepare regular cycle payroll</Card.Header>
                  <Card.Body>
                    <Card.Text className=" m-0">
                      <div className="d-flex w-100 align-items-center m-0 justify-content-space-between mb-6">
                        {processPayrollList.length ? (
                          <div className="heading f-18 w-75">
                            Recent process request was done by{" "}
                            <span>
                              &nbsp;{lastRegularCycleEntry?.name}&nbsp;
                            </span>{" "}
                            on
                            <span>
                              &nbsp;
                              {formatDate(
                                lastRegularCycleEntry?.payroll_processing_date
                              )}
                              &nbsp;
                            </span>
                            . Do you want to start the preparation process
                            again?
                          </div>
                        ) : (
                          <p className="p-0 m-0 heading f-18">
                            Your regular cycle payroll is successfully prepared. You can
                            proceed to process and run it now.
                          </p>
                        )}
                        <Buttons
                          type="button"
                          className="btn-primary text-white float-right prepare-btn"
                          disabled={auto_refresh}
                          loading={loading}
                          onClick={() => handleStageChange("run_payroll")}
                        >
                          PREPARE PAYROLL
                        </Buttons>
                      </div>
                    </Card.Text>
                    {auto_refresh && individualInfoText.regular && (
                      <div className="waiting-text text-left payroll-data-info-container">
                        <img
                          src={payrollInfoIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                        Payroll is processing. This may take under few mins
                        please wait!
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="prepare-run-wrapper">
          <div className="prepare-run-sub-wrapper">
            <div className="w-75">
              <div className="prepare-run-text-wrapper">
                <h3>Regular cycle payroll</h3>
                <p>
                  These are processed according to the scheduled pay periods for
                  the selected pay frequency, ensuring employees are paid
                  consistently and on time.
                </p>
              </div>
              <div className="prepare-run-text-wrapper">
                <h3>Here’s what happens when you run payroll:</h3>
                <ul>
                  <li>
                    Employee hours and PTO are automatically pulled from
                    timekeeping (if enabled).
                  </li>
                  <li>
                    Salaries, taxes, and deductions are calculated accurately.
                  </li>
                </ul>
              </div>
            </div>
            <div className="prepare-run-btn-wrapper">
              <Buttons
                type="button"
                className="btn-primary text-white float-right prepare-btn"
                disabled={auto_refresh}
                loading={loading}
                onClick={() => handleStageChange("run_payroll")}
              >
                PREPARE PAYROLL
              </Buttons>
            </div>
          </div>
        </div>
      )}
      {!completePayrollData.isfirstpayroll_off ? (
        <div className="exiting-payroll mb-3 px-1">
          <div className="header mb-3">
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>Prepare off cycle payroll</Card.Header>
                  <Card.Body>
                    <Card.Text className=" m-0">
                      <div className="d-flex w-100 align-items-center m-0 justify-content-space-between mb-6">
                        {processPayrollList.length ? (
                          <div className="heading f-18 w-75">
                            Recent process request was done by{" "}
                            <span>&nbsp;{lastOffCycleEnty?.name}&nbsp;</span> on
                            <span>
                              &nbsp;
                              {formatDate(
                                lastOffCycleEnty?.payroll_processing_date
                              )}
                              &nbsp;
                            </span>
                            . Do you want to start the preparation process
                            again?
                          </div>
                        ) : (
                          <p className="p-0 m-0 heading f-18">
                            Your off cycle payroll is successfully prepared. You can
                            proceed to process and run it now.
                          </p>
                        )}
                        <Buttons
                          type="button"
                          className="btn-primary text-white float-right prepare-btn"
                          disabled={auto_refresh}
                          loading={loading}
                          onClick={() =>
                            handleStageChange("run_off_cycle_payroll")
                          }
                        >
                          PREPARE PAYROLL
                        </Buttons>
                      </div>
                    </Card.Text>
                    {auto_refresh && individualInfoText.offCycle && (
                      <div className="waiting-text text-left payroll-data-info-container">
                        <img
                          src={payrollInfoIcon}
                          alt="payroll_icon"
                          className="me-2"
                        />
                        Payroll is processing. This may take under few mins
                        please wait!
                      </div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="prepare-run-sub-wrapper">
          <div className="w-75">
            <div className="prepare-run-text-wrapper">
              <h3>Off cycle payroll</h3>
              <p>
                These are processed outside the regular payroll cycle, allowing
                you to handle payments for specific dates and employees, such as
                bonuses, corrections, or special payouts.
              </p>
            </div>
            <div className="prepare-run-text-wrapper">
              <h3>Here’s what happens when you run an off cycle payroll:</h3>
              <ul>
                <li>
                  Employee hours and PTO are automatically pulled from
                  timekeeping (if enabled).
                </li>
                <li>
                  Salaries, taxes, and deductions are calculated accurately.
                </li>
              </ul>
            </div>
          </div>
          <div className="prepare-run-btn-wrapper">
            <Buttons
              type="button"
              className="btn-primary text-white float-right prepare-btn"
              disabled={auto_refresh}
              loading={loading}
              onClick={() => handleStageChange("run_off_cycle_payroll")}
            >
              PREPARE PAYROLL
            </Buttons>{" "}
          </div>
        </div>
      )}
    </>
  );
}
