import React, { useState, useEffect } from "react";
import { Form, Row, Col, InputGroup } from "react-bootstrap";
import "./style.scss";
import Button from "../../components/Form/Button";
import { useParams, useNavigate } from "react-router-dom";
import {
  mustLowerCaseLetters,
  mustSpecialCharacterCheck,
  mustUpperCaseLetters,
  emailRegx,
  autocloseTiming,
} from "../../api/regex";
import HeaderForm from "../../components/HeaderForm";
import { postData } from "../../api";
import { toast } from "react-toastify";
import Loader from "../../components/Loader";
import { FiEye, FiEyeOff } from "react-icons/fi";

const EmployeeOnboarding = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [employeeObject, setEmployeeObject] = useState({
    username: "",
    personal_email: "",
    password: "",
    password_confirmation: "",
    verfication_string: id,
    first_terms: false,
    second_terms: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    validateVerficationString();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const validateVerficationString = async () => {
    let data = {
      stage: "verfication",
      verfication: id,
    };
    setLoading(true);
    const res = await postData("gusto-employee-setup", {}, data);
    if (res.status === true) {
      setLoading(false);
      setEmployeeObject((prev) => ({
        ...prev,
        username: res.username,
        user_id: res.user_id,
      }));
      if (res.is_create === 1) {
        window.open("/employee-onboard-sections", "_self");
      }
    } else {
      navigate("/login");
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setEmployeeObject((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!!errors[name])
      setErrors({
        ...errors,
        [name]: null,
      });
  };

  const findFormErrors = () => {
    const {
      personal_email,
      password,
      password_confirmation,
      first_terms,
      second_terms,
    } = employeeObject;
    const newErrors = {};
    if (!personal_email || personal_email === "")
      newErrors.personal_email = "Please enter a valid email.";
    else if (!personal_email?.match(emailRegx)) {
      newErrors.personal_email = "Please enter a valid email address.";
    }
    if (!password || password === "")
      newErrors.password = "Please enter a valid password.";
    else {
      if (
        password !== "" &&
        (!mustSpecialCharacterCheck.test(password) ||
          !mustLowerCaseLetters.test(password) ||
          !mustUpperCaseLetters.test(password) ||
          password.length < 8)
      ) {
        newErrors.password = "invalid password";
      }
    }

    if (!password_confirmation || password_confirmation === "")
      newErrors.password_confirmation = "Please enter a valid password.";
    else if (password_confirmation.trim() !== password.trim()) {
      newErrors.password_confirmation = `Passwords must be the same..`;
    }
    if (!first_terms || !second_terms) {
      newErrors.terms = "Please acknowledge";
    }
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newErrors = findFormErrors();
    setValidated(true);
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      employeeOnboard();
    }
  };

  const employeeOnboard = async () => {
    setLoading(true);
    let data = {
      stage: "password",
      ...employeeObject,
    };
    setLoading(true);
    const res = await postData("gusto-employee-setup", {}, data);
    if (res.status === true) {
      setLoading(false);
      localStorage.setItem("guhroo_token", res.token);
      localStorage.setItem("guhroo_user", res.user_type);
      localStorage.setItem("guhroo_user_details", JSON.stringify(res));
      localStorage.setItem("guhroo_onbord_status", res.onboard_status);
      // localStorage.setItem(
      //   "user_completion_status ",
      //   res.user_completion_status
      // );
      localStorage.setItem("company_name", res.company_name);
      if (res.auto_url) {
        window.open(res.auto_url, "_self");
      }
    } else {
      toast.error(res.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };

  const handleRadioChange = (e) => {
    const { name, checked, value } = e.target;
    setEmployeeObject((prevState) => {
      const updatedState = {
        ...prevState,
        [name === "acknowledge" && value === "1"
          ? "first_terms"
          : "second_terms"]: checked,
      };
      if (updatedState.first_terms && updatedState.second_terms) {
        setErrors((prevErrors) => {
          const { terms, ...remainingErrors } = prevErrors;
          return remainingErrors;
        });
      }

      return updatedState;
    });
  };

  return (
    <>
      {!loading && (
        <>
          <HeaderForm />
          <div className="boarding-layout employee-onboarding-setup-wrapper">
            <div className="create-board">
              <div className="board-right">
                <div className="employee-title-wrapper">
                  <h5 className="mb-3 ">
                    Please complete your employee account setup
                  </h5>
                </div>
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="custom-form onboarding-form-wrapper"
                >
                  <Row className="px-4">
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Create new username"
                          name="username"
                          value={employeeObject.username}
                          onChange={handleInput}
                          isInvalid={!!errors.username}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="personal_email">
                        <Form.Label>
                          Personal Email <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Enter personal email"
                          value={employeeObject.personal_email}
                          onChange={handleInput}
                          isInvalid={!!errors.personal_email}
                          name="personal_email"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.personal_email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="px-4">
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="password">
                        <Form.Label>
                          Password <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            type={passwordShown ? "text" : "password"}
                            placeholder="Enter password"
                            name="password"
                            value={employeeObject.password}
                            onChange={handleInput}
                            isInvalid={!!errors.password}
                            required
                          />
                          <InputGroup.Text onClick={togglePasswordVisiblity}>
                            {passwordShown ? <FiEye /> : <FiEyeOff />}
                          </InputGroup.Text>
                          {validated && errors.password && (
                            <div className="err-feedback">
                              {errors.password === "invalid password" ? (
                                <div className="pass-invalid">
                                  Password should contain:
                                  <li> 8 or more characters</li>
                                  <li>At least one uppercase letter</li>
                                  <li>At least one lowercase letter</li>
                                  <li>At least one special characters</li>
                                </div>
                              ) : (
                                errors.password
                              )}
                            </div>
                          )}
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-3"
                        controlId="password_confirmation"
                      >
                        <Form.Label>
                          Confirm password <span className="asterisk">*</span>{" "}
                        </Form.Label>
                        <InputGroup className="toggle-password">
                          <Form.Control
                            name="password_confirmation"
                            type={confirmPasswordShown ? "text" : "password"}
                            placeholder="Enter confirm password"
                            onChange={handleInput}
                            value={employeeObject.password_confirmation}
                            isInvalid={!!errors.password_confirmation}
                            required
                          />
                          <InputGroup.Text
                            onClick={toggleConfirmPasswordVisiblity}
                          >
                            {confirmPasswordShown ? <FiEye /> : <FiEyeOff />}
                          </InputGroup.Text>
                          <Form.Control.Feedback type="invalid">
                            {errors.password_confirmation}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="term-condition-wrapper">
                    <Row className="mt-5">
                      <Col
                        md={12}
                        className="align-seld-center agree-subtitle-wrapper"
                      >
                        <Form.Group
                          className="ack-step-last mb-3"
                          controlId="acknowledge"
                        >
                          <Form.Check
                            label={
                              <span>
                                I agree to the
                                <a
                                  className="ms-1"
                                  href="https://www.guhroo.co/terms-of-service/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Terms of Service
                                </a>{" "}
                                including the{" "}
                                <a
                                  href="https://www.guhroo.co/privacy-policy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy
                                </a>{" "}
                                referenced in the TOS
                              </span>
                            }
                            name="acknowledge"
                            type="checkbox"
                            checked={employeeObject?.first_terms}
                            onChange={handleRadioChange}
                            value="1"
                            id="acknowledge_check_1"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col
                        md={12}
                        className="align-seld-center agree-subtitle-wrapper"
                      >
                        <Form.Group
                          className="ack-step-last mb-3"
                          controlId="acknowledge"
                        >
                          <Form.Check
                            label={
                              <span>
                                By agreeing to the TOS. I affirmatively consent
                                to the electronic disclosure of any notices,
                                including, but not limited to, taxt document
                                notices and employee benefits notices including
                                summary plan descriptions, COBRA notifications,
                                enrollment information and plan amendments, by
                                Guhroo to me. / authorize Gubroo to receive such
                                notices on behalf, and 1 agree to be notified of
                                such notices electronically at the email address
                                I am providing above.
                                <br /> Examples of notices include Medicare Part
                                D notices and W-2, 1099 and 1095 tax notices.
                              </span>
                            }
                            name="acknowledge"
                            type="checkbox"
                            checked={employeeObject?.second_terms}
                            onChange={handleRadioChange}
                            value="2"
                            id="acknowledge_check_2"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    {errors.terms && (
                      <div className="err-feedback">{errors.terms}</div>
                    )}
                  </div>

                  <div className="text-end mt-4 create-btn-wrapper px-4">
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      className="btn-primary text-white create-btn"
                    >
                      Create account
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </>
      )}
      {loading && <Loader />}
    </>
  );
};

export default EmployeeOnboarding;
