import React, { useEffect, useRef, useState } from "react";
import OnboardHeading from "../OnboardHeading";
import moment from "moment";
import "./style.scss";
import BackButtonComponent from "../backButton";
import ListImage from "../../assets/images/timekeeping_icons/list.svg";
import CalenderImage from "../../assets/images/timekeeping_icons/calendar_month.svg";
import Select from "react-select";
import { toast } from "react-toastify";
import { autocloseTiming } from "../../api/regex";
import { getRevertUser, postData } from "../../api";
import Loader from "../Loader";
import WarningIcon from "../../assets/images/timekeeping_icons/warning_icon.svg";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { debounce } from "lodash";
import { Col, Form, InputGroup } from "react-bootstrap";
import SearcIcon from "../../assets/images/timekeeping_icons/search_icon.svg";
import DateSort from "../../assets/images/timekeeping_icons/date_sort.svg";
import { MdClear } from "react-icons/md";
import RevertButtonComp from "../revertButton";

export default function TimeOffCalender() {
  const [employeeHoursData, setEmployeeHoursData] = useState([]);
  const [ptoStatus, setPtoStatus] = useState("pending");
  const [nameOptions, setNameOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupId, setGroupId] = useState(0);
  const [ptoPendingData, setPtoPendingData] = useState([]);
  const [ptoDeniedData, setPtoDeniedData] = useState([]);
  const [ptoApprovedData, setPtoApprovedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [checkedValues, setCheckedValues] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [allIds, setAllIds] = useState([]);
  const [selectTagValue, setSelectTagValue] = useState("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [loginUserId, SetLoginUserId] = useState(0);
  const [addRequestShow, setAddRequestShow] = useState(false);
  const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
  const [searchData, setSearchData] = useState("");
  const inputRef = useRef(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [pendingCount, setPendingCount] = useState(0);
  const [approvedCount, setApprovedCount] = useState(0);
  const [deniedCount, setDeniedCount] = useState(0);

  const currentDate = moment();
  const formattedDate = currentDate.format("dddd, MMMM Do");
  useEffect(() => {
    if (ptoStatus === "pending") {
      setEmployeeHoursData(ptoPendingData);
    } else if (ptoStatus === "denied") {
      setEmployeeHoursData(ptoDeniedData);
    } else {
      setEmployeeHoursData(ptoApprovedData);
    }
  }, [ptoApprovedData, ptoDeniedData, ptoPendingData, ptoStatus]);

  const getPtoList = async (typeValue) => {
    setLoading(true);
    let obj = {
      type: typeValue,
    };
    try {
      const res = await postData("pto-list", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        setPtoPendingData(res?.data?.pending);
        setPtoDeniedData(res?.data?.denied);
        setPtoApprovedData(res?.data?.approved);
        setGroupId(res?.group_id);
        SetLoginUserId(res?.login_user);
        setAddRequestShow(res?.add_request_show);
        setPendingCount(res?.data?.pending.length);
        setApprovedCount(res?.data?.approved.length);
        setDeniedCount(res?.data?.denied.length);
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  useEffect(() => {
    getPtoList("all");
  }, [nameOptions]);
  useEffect(() => {
    getPtoList("all");
  }, []);

  const handleButtonClick = async (id, buttonName) => {
    setLoading(true);
    let obj = {
      pto_ids: [id],
      content_type: buttonName,
    };
    try {
      const res = await postData("pto-approve-deny", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        if (res?.status === true) {
          toast.success(res.message, {
            theme: "colored",
            autoClose: 3000,
            style: {
              borderRadius: "16px",
            },
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  const handleApproveAllClick = async (buttonName) => {
    setLoading(true);
    let obj = {
      pto_ids: allIds,
      content_type: buttonName,
    };
    try {
      const res = await postData("pto-approve-deny", {}, obj);
      if (res?.status === true) {
        setLoading(false);
        toast.success(res.message, {
          theme: "colored",
          autoClose: 3000,
          style: {
            borderRadius: "16px",
          },
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      toast.error(err.message, {
        theme: "colored",
        autoClose: autocloseTiming,
      });
      setLoading(false);
    }
  };
  const handlePtoStatus = (status) => {
    setPtoStatus(status);
    setCurrentPage(0);
    setSearchData("");
    setFilteredEmployeeData(employeeHoursData);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleEmployeeSelectChange = (e) => {
    getPtoList(e.value);
    const defaultOption = nameOptions.find(
      (option) => option.label === e.label
    );
    setSelectTagValue(defaultOption);
  };
  const mapOptions = () => {
    const mappedOptions = [
      { value: "all", label: "All requests" },
      { value: "current", label: "Current pay periods" },
    ];
    setNameOptions(mappedOptions);
    if (isInitialLoad) {
      setSelectTagValue({ value: "all", label: "All requests" });
    }
  };

  useEffect(() => {
    mapOptions();
    setIsInitialLoad(false);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleFirstPage = () => {
    setCurrentPage(0);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages - 1);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(0, currentPage - 1);
    let endPage = Math.min(totalPages - 1, currentPage + 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          disabled={currentPage === i}
          onClick={() => handlePageChange(i)}
          className={
            currentPage === i
              ? "active-pagination-btn"
              : "non-active-pagination-btn"
          }
        >
          {i + 1}
        </button>
      );
    }

    return pageNumbers;
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(0);
  };

  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const totalPages = Math.ceil(filteredEmployeeData.length / rowsPerPage);

  useEffect(() => {
    const initialCheckedValues = employeeHoursData.map(() => ({
      isChecked: false,
    }));
    setCheckedValues(initialCheckedValues);
  }, [employeeHoursData]);

  const handleMainCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    const updatedCheckedValues = filteredEmployeeData.map((row) => ({
      ...row,
      isChecked: isChecked,
    }));

    setAllChecked(isChecked);
    setCheckedValues(updatedCheckedValues);

    if (isChecked) {
      const allIds = filteredEmployeeData
        .filter((row) => groupId === 3 || row.assigned_to === loginUserId)
        .map((row) => row.pto_id);
      setAllIds(allIds);
    } else {
      setAllIds([]);
    }
  };
  useEffect(() => {
    setFilteredEmployeeData(employeeHoursData);
  }, [employeeHoursData]);
  const handleIndividualCheckboxChange = (index) => {
    const updatedCheckedValues = [...checkedValues];
    updatedCheckedValues[index].isChecked =
      !updatedCheckedValues[index].isChecked;
    setCheckedValues(updatedCheckedValues);
    const ptoId = employeeHoursData[index].pto_id;
    const assignedTo = employeeHoursData[index].assigned_to;
    if (updatedCheckedValues[index].isChecked) {
      if (groupId === 3 || assignedTo === loginUserId) {
        setAllIds((prevIds) => [...prevIds, ptoId]);
      }
    } else {
      setAllIds((prevIds) => prevIds.filter((id) => id !== ptoId));
    }
  };
  const handleSearchChange = debounce((e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchData(searchValue);
    const filteredData = employeeHoursData.filter((row) => {
      if (row.full_name && typeof row.full_name === "string") {
        return row.full_name.toLowerCase().includes(searchValue);
      }
      return false;
    });
    const pendingFilterCount = filteredData.filter(
      (row) => row.status === "Pending"
    ).length;
    const approvedFilterCount = filteredData.filter(
      (row) => row.status === "Approved"
    ).length;
    const deniedFilterCount = filteredData.filter(
      (row) => row.status === "Denied"
    ).length;

    setFilteredEmployeeData(filteredData.length > 0 ? filteredData : []);

    if (ptoStatus === "pending") {
      setPendingCount(pendingFilterCount);
    } else if (ptoStatus === "approved") {
      setApprovedCount(approvedFilterCount);
    } else if (ptoStatus === "denied") {
      setDeniedCount(deniedFilterCount);
    }
    setAllIds([]);
    setCheckedValues(employeeHoursData.map(() => ({ isChecked: false })));
    setAllChecked(false);
  }, 500);
  const handleClear = () => {
    setSearchData("");
    setFilteredEmployeeData(employeeHoursData);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const tableDataArray =
    filteredEmployeeData.length > 0 ? filteredEmployeeData : [];
  const handleSortDate = () => {
    const dataToSort = searchData ? filteredEmployeeData : employeeHoursData;
    const sortedData = [...dataToSort].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      if (sortDirection === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    if (searchData) {
      setFilteredEmployeeData(sortedData);
    } else {
      setEmployeeHoursData(sortedData);
    }

    setSortDirection(sortDirection === "asc" ? "desc" : "asc");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="time-off-title-card">
            <OnboardHeading
              title="TIME OFF CALENDAR"
              subtitle={formattedDate}
            />

            <div className="btn-wrapper d-flex justify-content-between align-items-center">
              {addRequestShow === false ? null : (
                <a href="/time-off-cards">
                  <button className="add-btn">Add request</button>
                </a>
              )}
              {groupId === 5 || groupId === 8 || groupId === 4 ? null : (
                <a href="https://app.guhroo.co/reports/time_off_balance">
                  <button className="time-off-btn">
                    time off balance report
                  </button>
                </a>
              )}
            </div>
          </div>
          {getRevertUser() ? (
            <Col
              md={12}
              // sm={getRevertUser() ? 4 : 2}
              className="d-flex align-items-center justify-content-end mb-4"
            >
              <RevertButtonComp />
            </Col>
          ) : null}
          <div className="back-button-wrapper">
            <BackButtonComponent hasHistoryback />
            <div className="list-main-wrapper">
              <div className="d-flex justify-content-between">
                <div className="list-text-wrapper">
                  <img src={ListImage} alt="list-type" />
                  <p>List view</p>
                </div>
                <div className="calender-text-wrapper">
                  <img src={CalenderImage} alt="list-type" />
                  <a href="https://app.guhroo.co/ptos/calendar">
                    <p>Calendar view</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {groupId === 5 || groupId === 8 ? null : (
            <div className="time-off-cal-search-approve-wrapper">
              <div className="time-off-cal-input-search-wrapper">
                <InputGroup>
                  <img src={SearcIcon} alt="search-icon" />
                  <Form.Control
                    type="text"
                    placeholder="Search for employee by name"
                    onChange={handleSearchChange}
                    ref={inputRef}
                  />{" "}
                  {searchData && <MdClear onClick={handleClear} />}{" "}
                </InputGroup>
              </div>
              {groupId === 5 || groupId === 8
                ? null
                : employeeHoursData.length > 0 && (
                    <>
                      {ptoStatus === "pending" && (
                        <div className="d-flex approve-all-btn-wrapper">
                          <button
                            className={
                              allIds.length > 0
                                ? "approve-btn-active"
                                : "approve-timeoff-btn disabled-button"
                            }
                            onClick={() =>
                              allIds.length > 0
                                ? handleApproveAllClick("Approved")
                                : null
                            }
                          >
                            Approve
                          </button>
                          <button
                            className={
                              allIds.length > 0
                                ? "deny-btn-active"
                                : "deny-timeoff-btn disabled-button"
                            }
                            onClick={() =>
                              allIds.length > 0
                                ? handleApproveAllClick("Denied")
                                : null
                            }
                          >
                            Deny
                          </button>
                        </div>
                      )}
                    </>
                  )}
            </div>
          )}
          <div className="pto-dropdown-wrapper">
            <div className="btn-pto-wrapper">
              <button
                className={
                  ptoStatus === "pending"
                    ? "pto-log-current-btn active"
                    : "pto-log-current-btn"
                }
                onClick={() => handlePtoStatus("pending")}
              >
                Pending({searchData ? pendingCount : ptoPendingData.length})
              </button>
              <button
                className={
                  ptoStatus === "approved"
                    ? "pto-log-upcoming-btn active"
                    : "pto-log-upcoming-btn"
                }
                onClick={() => handlePtoStatus("approved")}
              >
                Approved({searchData ? approvedCount : ptoApprovedData.length})
              </button>
              <button
                className={
                  ptoStatus === "denied"
                    ? "pto-log-denied-btn active"
                    : "pto-log-denied-btn"
                }
                onClick={() => handlePtoStatus("denied")}
              >
                Denied({searchData ? deniedCount : ptoDeniedData.length})
              </button>
            </div>

            <div className="pto-filter-dropdown">
              <Select
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                options={nameOptions}
                placeholder="Select by filter"
                onChange={(e) => handleEmployeeSelectChange(e)}
                defaultValue={selectTagValue}
              />
            </div>
          </div>

          <div className="pto-table-container">
            <div className="pto-table-scroll">
              {tableDataArray.length > 0 ? (
                <table className="pto-table">
                  <thead>
                    <tr>
                      {groupId === 5 || groupId === 8
                        ? null
                        : ptoStatus === "pending" && (
                            <th style={{ width: "0%" }}>
                              <div className="d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  className="me-0"
                                  checked={
                                    checkedValues.length > 0 &&
                                    checkedValues.every((val) => val.isChecked)
                                  }
                                  onChange={handleMainCheckboxChange}
                                />
                              </div>
                            </th>
                          )}
                      <th style={{ width: "18%", textAlign: "left" }}>Name</th>
                      <th
                        style={{ width: "20%", textAlign: "left" }}
                        className="date-sort-wrapper"
                        onClick={handleSortDate}
                      >
                        Date <img src={DateSort} alt="date-sort" />
                      </th>
                      <th style={{ width: "10.5%", textAlign: "left" }}>
                        Leave Type
                      </th>
                      <th style={{ width: "10.5%", textAlign: "left" }}>
                        Hours
                      </th>
                      <th style={{ width: "10.5%", textAlign: "left" }}>
                        Reason
                      </th>
                      {groupId === 5 ||
                      groupId === 8 ||
                      ptoStatus !== "pending" ? null : (
                        <th style={{ width: "16%", textAlign: "left" }}>
                          Action
                        </th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableDataArray &&
                      tableDataArray
                        .slice(startIndex, endIndex)
                        .map((row, index) => (
                          <tr key={row.id}>
                            {ptoStatus === "pending" && (
                              <>
                                {groupId === 3 ? (
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <input
                                        type="checkbox"
                                        className="me-3"
                                        checked={
                                          checkedValues[index]?.isChecked
                                        }
                                        onChange={() =>
                                          handleIndividualCheckboxChange(index)
                                        }
                                      />
                                    </div>
                                  </td>
                                ) : groupId === 5 || groupId === 8 ? null : (
                                  <td>
                                    {row.assigned_to === loginUserId && (
                                      <div className="d-flex align-items-center">
                                        <input
                                          type="checkbox"
                                          className="me-3"
                                          checked={
                                            checkedValues[index]?.isChecked
                                          }
                                          onChange={() =>
                                            handleIndividualCheckboxChange(
                                              index
                                            )
                                          }
                                        />
                                      </div>
                                    )}
                                  </td>
                                )}
                              </>
                            )}
                            <td>{row.full_name}</td>
                            <td>
                              {moment(row.date).format("MM/DD/YYYY") ===
                              moment(row.date2).format("MM/DD/YYYY")
                                ? moment(row.date).format("MM/DD/YYYY")
                                : row.date2 !== null
                                ? `${moment(row.date).format(
                                    "MM/DD/YYYY"
                                  )}-${moment(row.date2).format("MM/DD/YYYY")}`
                                : moment(row.date).format("MM/DD/YYYY")}
                            </td>
                            <td>{row.pto_name}</td>
                            <td>{row.hours}</td>
                            <td>
                              <Tooltip
                                placement="left"
                                overlay={
                                  <div style={{ wordBreak: "break-word" }}>
                                    {row.reason}
                                  </div>
                                }
                                overlayClassName="timeoff-calender-tooltip"
                              >
                                <div>
                                  {row.reason.substring(0, 10)}
                                  {row.reason.length > 10 ? "..." : ""}
                                </div>
                              </Tooltip>
                            </td>
                            {groupId === 5 ||
                            groupId === 8 ||
                            ptoStatus !== "pending" ? null : row.assigned_to ===
                              loginUserId ? (
                              <td>
                                <div className="approve_deny_btn">
                                  <button
                                    onClick={() =>
                                      handleButtonClick(row.pto_id, "Approved")
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleButtonClick(row.pto_id, "Denied")
                                    }
                                  >
                                    Deny
                                  </button>
                                </div>
                              </td>
                            ) : groupId === 3 ? (
                              <td>
                                <div className="approve_deny_btn">
                                  <button
                                    onClick={() =>
                                      handleButtonClick(row.pto_id, "Approved")
                                    }
                                  >
                                    Approve
                                  </button>
                                  <button
                                    onClick={() =>
                                      handleButtonClick(row.pto_id, "Denied")
                                    }
                                  >
                                    Deny
                                  </button>
                                </div>
                              </td>
                            ) : (
                              <td>-</td>
                            )}
                          </tr>
                        ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-data-text">
                  <img src={WarningIcon} alt="warning" />
                  <h5>
                    {ptoStatus === "pending"
                      ? "There are no pending time off request"
                      : ptoStatus === "approved"
                      ? "There are no approved time off request"
                      : "There are no denied time off request"}
                  </h5>
                </div>
              )}
            </div>
            {employeeHoursData.length > 10 && (
              <div className="d-flex justify-content-between py-3 px-3">
                <div>
                  <select
                    value={rowsPerPage}
                    onChange={handleRowsPerPageChange}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
                <div>
                  {currentPage !== 0 && (
                    <>
                      <button
                        onClick={handleFirstPage}
                        className="non-active-pagination-btn"
                      >
                        &lt;&lt;
                      </button>
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="non-active-pagination-btn"
                      >
                        &lt;
                      </button>
                    </>
                  )}
                  {renderPageNumbers()}
                  {currentPage !== totalPages - 1 && (
                    <>
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="non-active-pagination-btn"
                      >
                        &gt;
                      </button>
                      <button
                        onClick={handleLastPage}
                        className="non-active-pagination-btn"
                      >
                        &gt;&gt;
                      </button>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
